$(document).ready(function () {
    function updateUnreadNotificationsCount(response) {
        if(response.unreadCount === 0) {
            $('#top-notification-count').remove();
        } else {
            $('#top-notification-count').html(response.unreadCount);
        }
        $('#top-notification-count-message').html(response.unreadMessage)
    }

    $('.notification-page-item').on('click', function() {
        let item = $(this);
        $.ajax({
            type: "POST",
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            dataType: 'json',
            url: $(this).data('post-url'),
            statusCode: {
                200: function(response){
                    item.removeClass('active');
                    item.find('.read-status').text('Lue');
                    item.blur();
                    updateUnreadNotificationsCount(response);
                    if(response.url) {
                        window.location.href = response.url;
                    } else {
                        $('#notification-items-tab-content [data-notification-id=' + response.id + ']').addClass('read');
                        $('#notification-items-tab-content [data-notification-id=' + response.id + ']').find('.ri-close-line').remove();
                        $('#notification-items-tab-content [data-notification-id=' + response.id + ']').find('.notification-item-content').off('click');
                        item.off('click');
                    }
                }
            }
        });
    });
    $('.notification-item-content').on('click', function(e) {
        e.stopPropagation();
        let item = $(this).parents('.top-notification-item');
        $.ajax({
            type: "POST",
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            dataType: 'json',
            url: item.data('post-url'),
            data: {
                source: 'topbar'
            },
            statusCode: {
                200: function(response){
                    item.addClass('read');
                    window.location.href = response.url;
                }
            }
        });
    });
    $('.close-notification').on('click', function(e) {
        e.stopPropagation();
        let button = $(this);
        let item = button.parents('.top-notification-item');
        $.ajax({
            type: "POST",
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            dataType: 'json',
            url: item.data('post-url'),
            statusCode: {
                200: function(response){
                    item.addClass('read');
                    button.remove();
                    item.find('.notification-item-content').off('click');
                    updateUnreadNotificationsCount(response);
                    $('#notifications-page-list [data-notification-id=' + response.id + ']').removeClass('active');
                    $('#notifications-page-list [data-notification-id=' + response.id + ']').find('.read-status').text('Lue');
                    if(!response.url) {
                        $('#notifications-page-list [data-notification-id=' + response.id + ']').off('click');
                    }
                }
            }
        });
    });
});